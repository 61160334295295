import { Module, Mutation, Action, VuexModule } from "vuex-module-decorators";

interface DataArray {
  [index: number]: Record<string, any>;
}

@Module({ namespaced: true })
class FormStore extends VuexModule {
  public data: DataArray = {};

  @Mutation
  public addFormData(payload: { _formKey: number; _data: any }): void {
    const { _formKey, _data } = payload;
    this.data[_formKey] = { ..._data };
  }

  @Mutation
  public addFormDataValue(payload: { _formKey: number; _objectKey: string; _value: string }): void {
    const { _formKey, _objectKey, _value } = payload;

    this.data[_formKey][_objectKey]["value"] = _value;
  }

  @Mutation
  public addFormDataValues(payloads: { _formKey: number; _values: { _objectKey: string; _value: string }[] }): void {
    const { _formKey, _values } = payloads;

    _values.forEach(({ _objectKey, _value }) => {
      this.data[_formKey][_objectKey]["value"] = _value;
    });
  }

  @Mutation
  public clearFormDataValues(_formKey: number): void {
    Object.keys(this.data[_formKey]).forEach((key) => {
      this.data[_formKey][key]["value"] = null;
    });
  }

  @Mutation
  public clearFormData(_formKey: number): void {
    this.data[_formKey] = {};
  }

  @Mutation
  public getFormDataItem(payload: { _formKey: number; _itemKey: string }): any {
    const { _formKey, _itemKey } = payload;

    return this.data[_formKey][_itemKey];
  }

  @Action
  public async addFormDataAction(payload: { _formKey: number; _data: any }): Promise<void> {
    this.context.commit("addFormData", payload);
  }

  @Action
  public async addFormDataValueAction(payload: {
    _formKey: number;
    _objectKey: string;
    _value: string;
  }): Promise<void> {
    this.context.commit("addFormDataValue", payload);
  }

  @Action
  public async addFormDataValuesAction(payloads: {
    _formKey: number;
    _values: { _objectKey: string; _value: string }[];
  }): Promise<void> {
    this.context.commit("addFormDataValues", payloads);
  }

  @Action
  public async getFormDataForSubmitAction(_formKey: number): Promise<any> {
    const formData = this.data[_formKey];
    return Object.fromEntries(
      Object.entries(formData)
        .filter(([key, value]) => value["includeInFormData"] || !value["disabled"])
        .map(([key, value]) => [key, value["value"]])
    );
  }

  @Action
  public async getFormDataAction(_formKey: number): Promise<any> {
    const formData = this.data[_formKey];

    return Object.fromEntries(Object.entries(formData).map(([key, value]) => [key, value["value"]]));
  }

  @Action
  public async getFormDataItemAction(payload: { _formKey: number; _itemKey: string }): Promise<any> {
    this.context.commit("getFormDataItem", payload);
  }

  @Action
  public async clearFormDataValuesAction(_formKey: number): Promise<void> {
    this.context.commit("clearFormDataValues", _formKey);
  }

  @Action
  public async clearFormDataAction(_formKey: number): Promise<void> {
    this.context.commit("clearFormData", _formKey);
  }
}

export default FormStore;
