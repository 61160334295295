import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { unref } from "vue";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";


@Module({ namespaced: true })
class AdChannelsStore extends VuexModule {
  public AdChannels = {};
  initialAdChannel = {};
  public ActiveAdChannel = this.initialAdChannel;

  get activeAdChannel() {
    return this.ActiveAdChannel;
  }

  get fetchedAdChannels() {
    return this.AdChannels;
  }

  @Mutation
  SET_ADCHANNEL(payload: any): void {
    this.ActiveAdChannel = payload;
  }

  @Mutation
  SET_ADCHANNELS(payload: any): void {
    this.AdChannels = payload;
  }

  @Mutation
  CLEAR_ADCHANNEL(): void {
    this.ActiveAdChannel = this.initialAdChannel;
  }

  @Action
  public clearAdChannel() {
    this.context.commit("CLEAR_ADCHANNEL");
  };

  @Action
  public fetchAdChannel(id: string | number) {
    if (id) {
      AxiosService.get(
        Endpoints.ENDPOINT_AD_CHANNELS + "/" + id
      ).then(async (response) => {
        const data = response.data.data;
        this.context.commit("SET_ADCHANNEL", data);
        this.context.commit("SET_ADCHANNELS", [data]);
      });
    }
  };

  @Action
  public fetchAdChannels(query: string | string[]) {
    const q = unref(query);
    if (q.length > 2) {
      const filter = "?filter" + "[name]" + "[like]=" + q;
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS + filter).then((response) => {
        const data = response.data.data;
        this.context.commit("SET_ADCHANNELS", data);
      });
    }
    else {
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS).then((response) => {
        const data = response.data.data;
        data.push(unref(this.ActiveAdChannel));
        this.context.commit("SET_ADCHANNELS", data);
      });
    }
  };
}

export default AdChannelsStore;
