import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface RegistrationInfo {
  step1: Step1;
  step2: Step2;
  step3: Step3;
  currentStepIndex: number;
  migrationLoad: boolean;
  finished: {
    step1: boolean;
    step2: boolean;
    step3: boolean;
  };
}

export interface Step1 {
  country: string;
  gui_language: number;
  language: number;
  email: string;
  name: string;
  surname: string;
  phone?: string | null;
  type: number;
  consent_tos: boolean | null,
  consent_gdpr: boolean | null,
  tos_url: string
}

export interface Step2 {
  account_type: number;
  company?: string;
  address: string;
  city: string;
  postal_code: string;
  reg_number?: string;
  vat_number?: string;
  itin_number?: string;
  reg_line?: string;
  birthdate?: string;
  iban?: string;
  bic?: string;
}

export interface Step3 {
  websites?: Array<string>;
  description?: string;
  connection?: string;
  recaptcha: string | unknown;
  password?: string;
  password_confirmation?: string;
  token?: string | undefined;
  residence_address?: string;
}

@Module({ namespaced: true })
class RegistrationModule extends VuexModule implements RegistrationInfo {
  step1 = {} as Step1;
  step2 = {} as Step2;
  step3 = {} as Step3;
  currentStepIndex = 1;
  migrationLoad = false;
  finished = {
    step1: false,
    step2: false,
    step3: false,
  };

  @Mutation
  public setDefault(): void {
    this.step1 = {} as Step1;
    this.step2 = {} as Step2;
    this.step3 = {} as Step3;
    this.currentStepIndex = 1;
    this.migrationLoad = false;
    this.finished = {
      step1: false,
      step2: false,
      step3: false,
    };
  }

  @Mutation
  public setStep1(payload: Step1): void {
    this.step1 = payload;
  }

  @Mutation
  public setStep2(payload: Step2): void {
    this.step2 = payload;
  }

  @Mutation
  public setStep3(payload: Step3): void {
    this.step3 = payload;
  }

  @Mutation
  public finishedStep(payload: { step: number; status: boolean }): void {
    const { step, status } = payload;
    (this.finished as { [key: string]: any })["step" + step] = status;
  }

  @Mutation
  public incrementStep(): void {
    this.currentStepIndex += 1;
  }

  @Mutation
  public decrementStep(): void {
    this.currentStepIndex -= 1;
  }

  @Mutation
  public changeMigrationStatus(status: boolean): void {
    this.migrationLoad = status;
  }

  @Action({ rawError: true })
  setMigrationUserData(payload: any): void {
    //this.context.commit("incrementStep");
    //this.context.commit("incrementStep");
    const step1 = {
      country: payload.country,
      gui_language: payload.gui_language,
      language: payload.language,
      email: payload.email,
      name: payload.name,
      surname: payload.surname,
      phone: payload.phone !== null ? payload.phone : "",
      type: payload.type,
      consent_tos: payload.consent_tos,
      consent_gdpr: payload.consent_gdpr,
      tos_url: payload.tos_url
    };

    const step2 = {
      account_type: Number(payload.account_type),
      iban: payload.iban,
      bic: payload.bic,
      birthdate: payload.birthdate,
      address: payload.address,
      city: payload.city,
      postal_code: payload.postal_code,
      company: payload.company,
      reg_number: payload.reg_number,
      vat_number: payload.vat_number,
      itin_number: payload.itin_number,
      reg_line: payload.reg_line,
    };
    //NOTE: maybe im view?
    if (payload.birthdate || payload.birthdate != "") {
      //NOTE: sets individual type / form
      step2.account_type = 2;
    }
    if (payload.company != null && (payload.company !== "" || payload.company.length > 0)) {
      //NOTE: sets company type / form
      step2.account_type = 1;
    }

    const step3 = {
      websites: payload.websites,
      description: payload.description,
      connection: payload.connection,
    };

    this.context.commit("setStep1", step1);
    this.context.commit("setStep2", step2);
    this.context.commit("setStep3", step3);
    this.context.commit("changeMigrationStatus", true);
  }
}

export default RegistrationModule;
