enum Endpoints {
  // ad-channels
  ENDPOINT_VIEW_AD_CHANNELS = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_AD_CHANNELS = "ad-channels",
  ENDPOINT_AD_CHANNELS_IN_CAMPAIGN = "ad-channels-campaigns?expand[]=campaign",
  ENDPOINT_AD_CHANNELS_IN_CAMPAIGN_DETAIL = "ad-channels-campaigns/:id",
  ENDPOINT_AD_CHANNELS_IN_CAMPAIGN_BASE = "ad-channels-campaigns",
  ENDPOINT_AD_CHANNELS_DETAIL = "ad-channels/:id",
  ENDPOINT_AD_CHANNELS_TYPES = "ad-channels/types",
  ENDPOINT_AD_CHANNELS_TYPES_GROUP = "ad-channels/types/groups",
  ENDPOINT_AD_CHANNELS_IN_CAMPAIGN_BULK = "ad-channels-campaigns/bulk",

  //roles
  ENDPOINT_ROLE = "roles",

  // logs

  ENDPOINT_VIEW_LOG = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_LOG_AUDIT = "log/audit",

  ENDPOINT_LOG_SYSTEM = "log/system",

  ENDPOINT_LOG_SALE = "log/sale",

  ENDPOINT_LOG_REDIR = "log/redirect",
  // commissions
  ENDPOINT_COMMISSION_COMPARISON = "commission-comparison",
  ENDPOINT_VIEW_COLUMN_UPDATE = "view-columns/:id",

  // reports

  ENDPOINT_REPORTS_TOP_AFFILIATES = "management-reports/top/affiliates",
  ENDPOINT_REPORTS_REFERRERS_CLICKS = "management-reports/top/click-referrers",

  ENDPOINT_REPORTS_REFERRERS_TRANSACTIONS = "management-reports/top/transaction-referrers",

  ENDPOINT_REPORTS_OVERVIEW = "management-reports/overview",

  ENDPOINT_REPORTS_KPI = "management-reports/kpi",

  ENDPOINT_REPORTS_BREAKDOWN = "management-reports/breakdown",

  // partners
  ENDPOINT_VIEW_PARTNER = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_PARTNER = "campaigns/partners?expand[]=campaign&expand[]=user",
  ENDPOINT_PARTNER_CREATE = "campaigns/partners",

  // mails
  ENDPOINT_VIEW_MAIL = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_MAIL = "log/mails",

  //accounts
  ENDPOINT_ACCOUNT = "accounts",
  ENDPOINT_ACCOUNT_METAS = "accounts/metas",
  ENDPOINT_VIEW_ACCOUNT = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_ACCOUNT_DETAIL = "accounts/:id?expand=metas",

  //banners
  ENDPOINT_BANNER = "banners",

  //payouts
  ENDPOINT_PAYOUT_AFFILIATES = "payouts/affiliates",
  ENDPOINT_PAYOUT_HISTORY = "payouts/history",
  ENDPOINT_PAYOUT_REQUEST = "payouts/request",

  ENDPOINT_VIEW_PAYOUT = "views?filter[resource_type][eq]=:resourceType",

  //onload
  ENDPOINT_COMMON_TRANSLATION = "common/translations?lang=",
  ENDPOINT_COMMON_ENUM = "common/enums?lang=",
  ENDPOINT_MENU = "menu",

  //migration
  ENDPOINT_MIGRATION = "user-migration/user",
  ENDPOINT_MIGRATION_GEN_TOKEN = "user-migration/token",

  //endpoints Auth
  ENDPOINT_AUTH_LOGIN = "auth/login",
  ENDPOINT_AUTH_LOGOUT = "auth/logout",
  ENDPOINT_AUTH_PASS_FORGOT = "auth/forgot-password",
  ENDPOINT_AUTH_PASS_RESET = "auth/reset-password",

  //register
  ENDPOINT_AUTH_REGISTER = "auth/signup",
  ENDPOINT_AUTH_REGISTER_MIGRATION = "user-migration/user",

  //exists
  ENDPOINT_EXISTS_USERS = "common/exists/user",

  //validate
  ENDPOINT_VALIDATE_IBAN = "common/validate/iban",
  ENDPOINT_VALIDATE_BIC = "common/validate/bic",

  //campaign
  ENDPOINT_VIEW_CAMPAIGN = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_CAMPAIGN = "campaigns",
  ENDPOINT_CAMPAIGN_DETAIL = "campaigns/:id",
  ENDPOINT_CAMPAIGN_DESCRIPTIONS = "campaigns/descriptions",
  ENDPOINT_CAMPAIGN_DESCRIPTIONS_DETAIL = "campaigns/descriptions/:id",
  ENDPOINT_SHORTCODES = "campaigns/descriptions/shortcodes",
  ENDPOINT_EXTERNAL_CAMPAIGN = "campaigns?filter[metas.remote_service_enabled][eq]=TRUE",
  ENDPOINT_CAMPAIGN_EXPAND_METAS_ACCOUNT_CATEGORY = "campaigns?expand[]=metas&expand[]=account&expand[]=campaignCategories",
  ENDPOINT_CAMPAIGN_EXPAND_PUBLISHER = "campaigns?expand[]=adChannels&expand[]=campaignPartners&expand[]=metas&expand[]=campaignCategories&expand[]=countries",
  ENDPOINT_CAMPAIGN_EXPAND_PUBLISHER_DETAIL = "campaigns/:id?expand[]=adChannels&expand[]=campaignPartners&expand[]=metas&expand[]=campaignCategories&expand[]=countries&expand[]=descriptionShortcodes",
  //ENDPOINT_CAMPAIGN_EXPAND_MARKET = "campaigns?expand[]=countries", //tototoototo este treba SCOPE
  ENDPOINT_CAMPAIGN_CATEGORIES = "campaigns/categories?per-page=100000&filter[active][eq]=1&expand[]=children",
  //ENDPOINT_CAMPAIGN_CATEGORIES = "campaigns/categories?per-page=100000",

  ENDPOINT_CAMPAIGN_SETTINGS = "campaigns/settings",
  ENDPOINT_CAMPAIGN_METAS = "campaigns/metas",
  ENDPOINT_CAMPAIGN_GENERATE_LINK = "campaigns/links/generate",
  ENDPOINT_CAMPAIGN_DEFAULT_LINK = "campaigns/links",

  //integration-processes-campaign
  ENDPOINT_VIEW_INTEGRATION_PROCESSES_CAMPAIGN = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_INTEGRATION_PROCESSES_CAMPAIGN = "integration-processes",
  //user
  ENDPOINT_VIEW_USER = "views?filter[resource_type][eq]=:resourceType",

  ENDPOINT_VIEW_TEST = "users?filter[account_id][eq]=:id",

  ENDPOINT_USER = "users",
  ENDPOINT_USER_ACCOUNT_ID = "users?filter[account_id][eq]=:accountId",
  ENDPOINT_MERCHANT = "users?filter[and][0][type][eq]=1",

  ENDPOINT_PUBLISHER = "users?filter[and][0][type][eq]=2",
  ENDPOINT_USER_DETAIL = "users/:id?expand[]=metas&expand[]=account",
  ENDPOINT_USER_UPDATE = "users/:id",

  ENDPOINT_USER_CREATE = "users",

  ENDPOINT_USER_METAS = "users/metas",

  ENDPOINT_USER_CHANGE_PASSWORD = "users/change-password",

  //translations
  ENDPOINT_VIEW_TRANSLATION = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_TRANSLATION = "translations/multi",
  ENDPOINT_TRANSLATION_CREATE = "translations",
  ENDPOINT_TRANSLATION_GROUP = "translations/groups",

  //transactions
  ENDPOINT_VIEW_TRANSACTION = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_TRANSACTION = "transactions",
  ENDPOINT_TRANSACTION_CREATE = "transactions",

  //raw-transactions
  ENDPOINT_RAW_TRANSACTION = "raw-transactions",
  ENDPOINT_RAW_TRANSACTION_RECALCULATE_COMMISSION = "raw-transactions/recalculate-commission",
  ENDPOINT_RAW_TRANSACTIONS_IMPORT = "raw-transactions/import",

  // translation export
  ENDPOINT_BACKGROUND_TASK = "background-tasks/",
  ENDPOINT_TRANSLATION_EXPORT = "translations/export",

  //translation import
  ENDPOINT_TRANSLATION_IMPORT = "translations/import",

  //mail templates
  ENDPOINT_VIEW_MAIL_TEMPLATES_MODULES = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_MAIL_TEMPLATES_MODULES = "mail-templates/modules",
  ENDPOINT_MAIL_TEMPLATES_MODULES_DETAIL = "mail-templates/modules/:id",
  ENDPOINT_VIEW_MAIL_TEMPLATES = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_MAIL_TEMPLATES = "mail-templates",
  ENDPOINT_MAIL_TEMPLATES_DETAIL = "mail-templates/:id",
  ENDPOINT_MAIL_TEMPLATES_PREVIEW = "mail-templates/preview",
  ENDPOINT_MAIL_TEMPLATES_GROUP = "mail-templates?filter[code][eq]=:code",

  //clicks
  ENDPOINT_VIEW_CLICKS = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_CLICKS = "clicks",

  //feeds
  ENDPOINT_CAMPAIGNS_VIEW_FEEDS = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_CAMPAIGNS_FEEDS = "campaigns/feeds",
  ENDPOINT_CAMPAIGNS_FEEDS_EXPAND = "campaigns/feeds?expand[]=campaign",
  ENDPOINT_CAMPAIGNS_FEEDS_USERS = "campaigns/feeds/:id/users",

  //file-manager
  ENDPOINT_VIEW_FILE_MANAGER = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_FILE_MANAGER = "files",
  ENDPOINT_FILE_MANAGER_DETAIL = "files/:id",
  ENDPOINT_FILE_MANAGER_UPLOAD = "files",
  ENDPOINT_FILE_MANAGER_TAGS = "files/tags?per-page=100000",
  ENDPOINT_FILE_MANAGER_DELETE = "files/:id",

  //automations
  ENDPOINT_AUTOMATIONS = "automations",
  ENDPOINT_VIEW_AUTOMATIONS = "views?filter[resource_type][eq]=:resourceType",
  ENDPOINT_AUTOMATIONS_TREE_SAVE = "automations/tree",
  ENDPOINT_AUTOMATIONS_TREE_SHOW = "automations/:id/tree",
  ENDPOINT_RULES = "automations/rules/:hook",
  ENDPOINT_ACTIONS = "automations/actions/:hook",
  ENDPOINT_GET_CONDITION_TEST_TREE = "/automations/tree/test/condition/:hook",
  ENDPOINT_CONDITION_TEST_TREE = "automations/tree/test/condition",
  ENDPOINT_CONDITION_TEST_FULL = "automations/tree/test/full",

  ENDPOINT_BACKGROUND_TASK_ID = "background-tasks/:id/result",

  //coupons
  ENDPOINT_COUPON = "coupons",
  ENDPOINT_COUPON_IMPORT = "coupons/import",

  //BASE
  ENDPOINT_BASE_VIEW = "views?filter[resource_type][eq]=:resourceType",
}

export { Endpoints };
